.container {
  position: relative;
}

.blobpagest {
  display: inline-block;
  filter: blur(300px);
  z-index: -1;
  position: absolute;
  left: 0rem;
  top: 100%;
  transform: scale(1.7);
}


.dashed-line {
  margin-top: 0%;
  border: 3px dashed #FFFFFF;
}

h5{
  color: #01FDFE;
}

.topimg
  {
    width: 100%;
  }

.btn-register {
  position: absolute;
  top: 80%;
  right: 5%;
  z-index: 99;
  font-family: "lato", sans-serif;
  text-decoration: none;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.5);
  background: rgba(255,255,255,0.1);
  border-top: 1px solid rgba(255,255,255,0.5);
  border-left: 1px solid rgba(255,255,255,0.5);
  backdrop-filter: blur(5px);
  padding: 20px 60px;
  border-radius: 12px;
  transition: box-shadow .2s ease-in-out;
}

.btn-register:hover {
  box-shadow: 2px 2px 15px rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(15px);
}

.register {
  color: white;
  font-size: 1.4rem;
}
  
.hawkpara {
  color: white;
  font-size: 28px;
  margin-right:5%;
  text-align: justify;
  font-family: "lato", sans-serif;
  line-height: 35px;
}
  
.solo
{
  font-size: 28px;
}
  
  
  
.about0 {
  position: relative;
  margin-top: 3%;
  font-family: Mokoto;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 28px;
  letter-spacing: 0.08em;
  color: #01FDFE;
}
  
.about1 {
  position: absolute;
  margin-top: 2%;
  font-family: Mokoto;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 28px;
  letter-spacing: 0.08em;
  color: #01FDFE;
}
  
  .about0 span{
    font-family: Mokoto;
  }
  
  .about1 span{
    font-family: Mokoto;
  }
  
  .about2 span{
    font-family: Mokoto;
  }
  
  .about3 span{
    font-family: Mokoto;
  }
  
  .about4 span{
    font-family: Mokoto;
  }
  
  .about2 {
    position: absolute;
    margin-top: 6%;
  
    font-family: Mokoto;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 28px;
    letter-spacing: 0.08em;
  
    color: #01FDFE;
  }
  
  .about3 {
    position: absolute;
    margin-top: 10%;
  
    font-family: Mokoto;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 28px;
    letter-spacing: 0.08em;
  
    color: #01FDFE;
  }
  
  .about4 {
    position: absolute;
    margin-top: 14%;
  
    font-family: Mokoto;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 28px;
    letter-spacing: 0.08em;
  
    color: #01FDFE;
  }
  
  .prizeh
  {
    margin-top: 5%;
    text-wrap: nowrap;
    margin-left: 23%;
  }
  
  .hawkimg {
    margin-top: 300px;
  }
  
  .structure {
    margin-top: 25%;
  
    font-family: Mokoto;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 28px;
    letter-spacing: 0.08em;
  
    color: #01FDFE;
  }
  
  .pcontainer {
    /* "]\color : "white", "font-size" : "20px", "marginTop" : "20px"}} */
    /* margin-top: 3%; */
    color: white
  }
  
  .structureul {
    font-family: "lato", sans-serif;
    margin-left: 5%;
    margin-top: 3%;
    color: white;
    font-size: 28px;
    margin-right:5%;
    line-height: 35px;
  }
  
  .containerleft {
    margin-left: 5%;
  
  }
  
  .registernowbutton
    {
      width:35%;
      /* text-align: left; */
      margin-left: 60%;
      margin-top: -10%;
    }
  .generalrulesheading {
    margin-top: 50%;
    font-family: Mokoto;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 28px;
    letter-spacing: 0.08em;
  
    color: #01FDFE;
    margin-top: 5%;
    margin-bottom: 2%;
  }
  
  .generalrules {
    margin-top: -1%;
  }
  
  .abcde {
    margin-left: 5%;
  }
  
  
  .contact {
    margin-top: 5%;
  }
  
  .contbot {
    margin-bottom: 5%;
  }
  .notbold
  {
    font-weight: normal;
    font-size: 32px;
  }
  .prize
  {
    margin-left: 38%;
  }
  .deskcont
  {
    margin-top: 10px;
    font-size: 28px;
    font-weight: normal;
    margin-left: 5%;
  }
  
  
  @media screen and (max-width: 600px) {
    .topimg
    {
      margin-top: 10%;
      width: 100%;
    }
    .registernowbutton
    {
      width:60%;
      /* text-align: left; */
      margin-left: 40%;
      margin-top: -10%;
    }
    .dashed-line {
      margin-top: 5%;
      border: 2px dashed #FFFFFF;
      /* transform: rotate(-179.9deg); */
    }
    .hawkpara {
      color: white;
      font-size: 18px;
    }
  
    .about0 {
      position: relative;
  
  
      font-family: Mokoto;
      font-style: normal;
      font-weight: 800;
      font-size: 21px;
      line-height: 28px;
      letter-spacing: 0.08em;
  
      color: #01FDFE;
    }
  
    .about1 {
      position: absolute;
      margin-top: 5%;
  
      font-family: Mokoto;
      font-style: normal;
      font-weight: 800;
      font-size: 21px;
      line-height: 28px;
      letter-spacing: 0.08em;
  
      color: #01FDFE;
    }
  
    .about2 {
      position: absolute;
      margin-top: 17%;
  
      font-family: Mokoto;
      font-style: normal;
      font-weight: 800;
      font-size: 21px;
      line-height: 28px;
      letter-spacing: 0.08em;
  
      color: #01FDFE;
    }
  
    .about3 {
      position: absolute;
      margin-top: 28%;
  
      font-family: Mokoto;
      font-style: normal;
      font-weight: 800;
      font-size: 21px;
      line-height: 28px;
      letter-spacing: 0.08em;
  
      color: #01FDFE;
    }
  
    .about4 {
      position: absolute;
      margin-top: 39%;
  
      font-family: Mokoto;
      font-style: normal;
      font-weight: 800;
      font-size: 21px;
      line-height: 28px;
      letter-spacing: 0.08em;
  
      color: #01FDFE;
    }
  
  
    .structure {
      margin-top: 55%;
  
      font-family: Mokoto;
      font-style: normal;
      font-weight: 800;
      font-size: 21px;
      line-height: 28px;
      letter-spacing: 0.08em;
  
      color: #01FDFE;
    }
  
    .pcontainer {
      /* "]\color : "white", "font-size" : "20px", "marginTop" : "20px"}} */
      margin-top: 5%;
      color: white
    }
  
    .structureul {
      margin-left: 5%;
      margin-top: 3%;
      color: white;
      font-size: 18px;
    }
  
    .containerleft {
      margin-left: 5%;
  
    }
  
    .generalrulesheading {
      margin-top: 50%;
      font-family: Mokoto;
      font-style: normal;
      font-weight: 800;
      font-size: 21px;
      line-height: 28px;
      letter-spacing: 0.08em;
  
      color: #01FDFE;
      margin-top: 5%;
      margin-bottom: 2%;
    }
  
    .generalrules {
      margin-top: 0%;
    }
  
    .abcde {
      margin-left: 5%;
    }
  
    .upperimage {
      margin-top: -10%;
    }
  
    .contact {
      margin-top: 5%;
    }
  
    .contbot {
      margin-bottom: 5%;
    }
    .notbold
    {
      font-weight: normal;
      font-size: 18px;
    }
    .prize
    {
      margin-left: 38%;
    }
    .deskcont
  {
    margin-top: 10px;
    margin-left: 5%;
    font-size: 21px;
    font-weight: normal;
  }
  }

@media only screen and (max-width: 425px) {
  .btn-register {
    top: 100%;
    right: 0;
    padding: 10px 20px;
  }

  .dashed-line {
    margin-top: 4rem;
  }

  .pcontainer {
    margin-top: 0;
  }
}