
.Hero {
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo {
    position: fixed;
    top: 0;
    left: 0;
    margin: 30px 40px;
    z-index: 9999;
}

.logo img {
    width: 3rem;
}
  
.three-model {
    height: 100%;
    width: 100%;
    scale: .9;
    z-index: 11;
} 

.three-model .mobile {
    display: none;
}
  
.text-bg{
    position: absolute;
    width: 100%;
    top: 63%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    display: flex;
    justify-content: center;
    gap: 12vw;
    padding: 0 10rem;
}
  
.hero-txt {
    font-size: 8rem;
    background: -webkit-linear-gradient(#fff, #01fefe75);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow:     0 1px 0 hsl(174,5%,80%),
                       0 2px 0 hsl(174,5%,75%),
                       0 3px 0 hsl(174,5%,70%),
                       0 4px 0 hsl(174,5%,66%),
                       0 5px 0 hsl(174,5%,64%),
                       0 6px 0 hsl(174,5%,62%),
                       0 7px 0 hsl(174,5%,61%),
                       0 8px 0 hsl(174,5%,60%),
      
                       0 0 5px rgba(68, 66, 66, 0.05),
                      0 1px 3px rgba(68, 66, 66,.2),
                      0 3px 5px rgba(68, 66, 66,.2),
                     0 5px 10px rgba(68, 66, 66,.2),
                    0 10px 10px rgba(68, 66, 66,.2),
                    0 20px 20px rgba(68, 66, 66,.3);
}
  
.hero-txt:before {
  content: attr(data-text);
      -webkit-background-clip: none;
      -webkit-text-fill-color: #01FDFE;
      position: absolute;
      z-index: -1;
      filter: blur(14px); 
      transform: skew(67deg, 0) translate(-30%, 12%) scaleY(0.5);
      -webkit-mask-image: linear-gradient(180deg, rgba(86, 14, 153, 0) 0%, rgb(255, 255, 255) 100%);
      padding: 0 22px;
}
  
.scroll-sec {
    font-family: "Poppins", sans-serif;
    position:absolute;
    bottom: 0;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
    cursor: pointer;
    z-index: 10;
}
  
.scroll-sec .arrow {
    padding: 8px;
    border: 1px solid #fff;
    border-radius: 50%;
    -webkit-animation: bouncing 5s ease-out infinite normal;
    animation: bouncing .8s ease-in-out infinite alternate;
}

.arrow i {
    transform:rotate(270deg)
}


@-webkit-keyframes bouncing {
    100% {
        -webkit-transform: translateY(8px);
        transform: translateY(8px);
    }
}

@keyframes bouncing {
    100% {
        -ms-transform: translateY(8px);
        -webkit-transform: translateY(8px);
        transform: translateY(8px);
    }
}
 
  
.moto {
    margin-top: -10rem;
    z-index: 11;
}
  
.moto h3 {
    color: white;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 18px;
}
  
.moto h3 span {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #01FDFE;
}
  
  
@media only screen and (max-width: 1200px){
    .hero-txt {
      font-size: 7rem;
    }
  
    .three-model {
      scale: .8;
    }
}
  
@media only screen and (max-width: 768px){
    .text-bg{
        top: 61%;
        gap: 16vw;
    }

    .moto {
        margin-top: -10rem;
    }

    .hero-txt {
        font-size: 5rem;
    }

    .three-model {
        scale: .7;
    }
}

@media only screen and (max-width: 425px) {
    .three-model {
        margin-top: -4rem;
        z-index: -1;
    }

    .three-model .web {
        display: none;
    }

    .logo {
        display: none;
    }
    .three-model .mobile {
        display: block;
    }

    .text-bg{
        position: absolute;
        width: 100%;
        top: 61%;
        gap: 0;
        padding: 0 0rem;
    }
    .hero-txt {
        font-size: 2.8rem;
    }
    .moto {
        margin-top: -12rem;
    }
    .moto h3 {
        font-size: 14px;
    }
    .moto h3 span {
        font-size: 14px;
    }

    .hero-txt:before {
        transform: skew(67deg, 0) translate(-35%, 18%) scaleY(0.5);
      }

      .scroll-sec .arrow {
        margin-top: -12rem;
      }

}