.about {
    color: white;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
  
.blob {
    display: inline-block;
    filter: blur(300px);
    z-index: -1;
    position: absolute;
    left: 10rem;
    bottom: 8rem;
    transform: scale(1.7);
}
  
  #goo1, #goo2{
    transform-origin: center;
}
  
  #goo1{
    animation: rotate 24s ease infinite alternate;
}
  
#goo2{
    animation: rotate 24s ease infinite alternate-reverse;
}
  
@keyframes rotate{
    0%{
        transform: rotate(20deg)
    }
    
    50%{
        transform: rotate(60deg) scale(.5)
    }
}
  
.content {
    height: 100%;
    width: 80%;
    display: flex;
    align-items: center;
  }
  
  
.textSection {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}
  
  
.textSection .mainHead {
    font-family: mokoto;
    font-weight: 100;
    font-style: normal;
    font-size: 4rem;
    padding: 20px 20px 0px 20px;
    background: -webkit-linear-gradient(#fff, rgba(14, 144, 196, 0.666));   
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow:     0 1px 0 hsl(174,5%,80%),
                       0 1px 0 hsl(174,5%,75%),
                       0 2px 0 hsl(174,5%,70%),
                       0 3px 0 hsl(174,5%,66%),
                       0 4px 0 hsl(174,5%,64%),
                       0 5px 0 hsl(174,5%,62%),
                       0 6px 0 hsl(174,5%,61%),
                       0 7px 0 hsl(174,5%,60%),
      
                       0 0 5px rgba(68, 66, 66, 0.05),
                      0 1px 3px rgba(68, 66, 66,.2),
                      0 3px 5px rgba(68, 66, 66,.2),
                     0 5px 10px rgba(68, 66, 66,.2),
                    0 10px 10px rgba(68, 66, 66,.2),
                    0 20px 20px rgba(68, 66, 66,.3);
}

.textSection .mainHead::before {
    content: "About us";
      -webkit-background-clip: none;
      -webkit-text-fill-color: #01FDFE;
      position: absolute;
      z-index: -1;
      filter: blur(10px); 
      transform: skew(67deg, 0) translate(-20%, 14%) scaleY(0.5);
      -webkit-mask-image: linear-gradient(180deg, rgba(86, 14, 153, 0) 0%, rgb(255, 255, 255) 100%);
      padding: 0 22px;
}
  
.paraSectoin {
    display: flex;
    position: relative;
    width: 95%;
    height: 90%;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.55rem;
    margin-left: 5rem;
    margin-top: 1rem;
    padding: 20px 20px 60px 19rem;
    border-radius: 10px;
    box-shadow: 20px 20px 50px rgba(0,0,0,0.5);
    border-radius: 15px;
    background: rgba(255,255,255,0.1);
    border-top: 1px solid rgba(255,255,255,0.5);
    border-left: 1px solid rgba(255,255,255,0.5);
    backdrop-filter: blur(5px);
    cursor: default;
    text-align: center;
    line-height: 40px;
    letter-spacing: 1px;
}

.abtimg {
    position: absolute;
    z-index: 11;
    width: 90%;
    top: -35%;
    left: -35%;
    transform: scale(1.01);
    transition: transform .4s ease-in-out;
    -webkit-animation: breathing 5s ease-out infinite normal;
    animation: breathing 2s ease-out infinite alternate;
}


@-webkit-keyframes breathing {
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes breathing {
    100% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
}
  
@media only screen and (max-width: 1024px) {
    .abtimg {
        width: 40%;
        top: -35%;
        left: -15%;
    }
    .paraSectoin {
        font-size: 1.4rem;
        line-height: 30px;
        letter-spacing: 1px;
    }
}
  
@media only screen and (max-width: 768px) {
    .textSection .mainHead {
        font-size: 4rem;
    }

    .abtimg {
        width: 30%;
        top: -15%;
        left: -15%;
    }

    .paraSectoin {
        padding: 20px 20px 60px 6rem;
    }
}

@media only screen and (max-width: 425px) {
    .about {
        margin-top: -3rem;
    }

    .blob {
        display: inline-block;
        filter: blur(300px);
        z-index: -1;
        position: absolute;
        left: -10rem;
        top: 0;
        transform: scale(1.7);
    }

    .textSection .mainHead {
        font-size: 3rem;
        padding: 0;
        text-wrap: nowrap;
    }

    .abtimg {
        display: none;
    }

    .paraSectoin {
        padding: 20px;
        font-size: 18px;
        line-height: 25px;
        z-index: 11;
        text-align: left;
        margin-top: 2rem;
    }
}