@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@font-face {
  font-family: mokoto;
  src: url(../public/mokoto.regular.ttf);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
}

.App {
  background-color: transparent;
  width: 100vw;
  height: 100vh;
}
body {
  position: absolute;
  font-style: normal;
  background-color: #0c0c0c;
  width: 100vw;
  height: 100vh;
}


::-webkit-scrollbar {
  width: 1.1rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  box-shadow: 20px 20px 50px rgba(0,0,0,1);
  background: #01FDFE;
  margin: 1px;
  border-top: 1px solid rgba(255,255,255,0.5);
  border-left: 1px solid rgba(255,255,255,0.5);
  backdrop-filter: blur(5px);
}

::-webkit-scrollbar-track {
  margin: 20px 0;
  border-radius: 6px;
  box-shadow: 20px 20px 50px rgba(0,0,0,0.5);
  background: rgba(255,255,255,0.1);
  border-top: 1px solid rgba(255,255,255,0.5);
  border-left: 1px solid rgba(255,255,255,0.5);
  backdrop-filter: blur(5px);
}

.flex-centre {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}