.Events {
    color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.heading {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 70%;
    margin-bottom: 6rem;
}

.heading div {
    font-family: mokoto;
    font-weight: 100;
    font-style: normal;
    font-size: 4rem;
    padding: 20px 20px 0px 20px;
    background: -webkit-linear-gradient(#fff, rgba(14, 144, 196, 0.666));   
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow:     0 1px 0 hsl(174,5%,80%),
                       0 1px 0 hsl(174,5%,75%),
                       0 2px 0 hsl(174,5%,70%),
                       0 3px 0 hsl(174,5%,66%),
                       0 4px 0 hsl(174,5%,64%),
                       0 5px 0 hsl(174,5%,62%),
                       0 6px 0 hsl(174,5%,61%),
                       0 7px 0 hsl(174,5%,60%),
      
                       0 0 5px rgba(68, 66, 66, 0.05),
                      0 1px 3px rgba(68, 66, 66,.2),
                      0 3px 5px rgba(68, 66, 66,.2),
                     0 5px 10px rgba(68, 66, 66,.2),
                    0 10px 10px rgba(68, 66, 66,.2),
                    0 20px 20px rgba(68, 66, 66,.3);
}

.heading div::before {
    content: "Events";
      -webkit-background-clip: none;
      -webkit-text-fill-color: #01FDFE;
      position: absolute;
      z-index: -1;
      filter: blur(10px); 
      transform: skew(67deg, 0) translate(-27%, 14%) scaleY(0.5);
      -webkit-mask-image: linear-gradient(180deg, rgba(86, 14, 153, 0) 0%, rgb(255, 255, 255) 100%);
      padding: 0 22px;
}

.cards {
    display: grid;
    place-items: center;
    grid-template-columns: 1fr 1fr;
    gap: 5rem;
}

@media only screen and (max-width: 768px) {
    .cards {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10rem;
    }
}

@media only screen and (max-width: 425px) {
    .heading {
        margin-bottom: 3rem;
    }
    .heading div {
        font-size: 3rem;
        padding: 0;
        text-wrap: nowrap;
    }

    .Events {
        margin-left: .5rem;
    }

    .cards {
        gap: 5rem;
        margin: 0;
    }

}